import React, { FC } from 'react';
import { graphql, Link } from 'gatsby';
import moment from 'moment';

import Layout from '../../layout/layout';
import { GatsbyTemplateProps } from '../../../common/interfaces/gatsby-template-props.interface';
import PressReleaseContactInfo from '../components/press-release-contact-info';
import { PressReleasesContext } from './press-release.gatsby';
import { Helmet } from 'react-helmet';
import { TopNav } from '../../layout';
import { AppContextProps } from '../../../common/interfaces/locale-props.interface';
import { LocaleLink } from '../../common/links/LocaleLink';
import { MainFooter } from '../../layout/footer/footer.interfaces';
import { ContentfulNavigation } from '../../../contentful/content-types/navigation';
import { useTranslations } from '../../../common/hooks/useTranslations';

interface PressRelease {
  id: string;
  title: string;
  orderDate: string;
  urlSlug: string;
}

interface PressReleaseQueryResult {
  allPressRelease: {
    totalCount: number;
    nodes: PressRelease[];
  };
  navData: TopNav;
  redwoodNavData: ContentfulNavigation;
  redwoodRightNavData: ContentfulNavigation;
  utilityNavData: ContentfulNavigation;
  footerData: MainFooter;
}

export interface PressReleaseTemplateProps
  extends GatsbyTemplateProps<PressReleasesContext & AppContextProps> {
  data: PressReleaseQueryResult; // passed from gatsby results from graphql pageQuery
  navAbTestData: TopNav;
}

export const pageQuery = graphql`
  query($yearStart: Date!, $yearEnd: Date!, $locale: String!) {
    allPressRelease(
      filter: { orderDate: { gte: $yearStart, lte: $yearEnd }, locale: { eq: $locale } }
      sort: { fields: orderDate, order: DESC }
    ) {
      nodes {
        id
        title
        orderDate
        urlSlug
      }
    }
    navData: mainTopNav(contentfulId: { eq: "3Ygy1wQCWTJl9iFRLTd0NV" }, locale: { eq: $locale }) {
      ...mainTopNavFragment
    }
    redwoodNavData: navNavigation(
      contentfulId: { eq: "5BEIZi0JdHhdU8ABizjFbY" }
      locale: { eq: $locale }
    ) {
      ...navigationFragment
    }
    redwoodRightNavData: navNavigation(
      contentfulId: { eq: "55yBvRCoANQ6LSDRS8y34S" }
      locale: { eq: $locale }
    ) {
      ...navigationFragment
    }
    utilityNavData: navNavigation(
      contentfulId: { eq: "3Ubk7uPrpCVkhQ3ZYXZLOp" }
      locale: { eq: $locale }
    ) {
      ...navigationFragment
    }
    footerData: mainFooter(locale: { eq: $locale }) {
      ...mainFooterFragment
    }
    navAbTestData: mainTopNav(
      contentfulId: { eq: "5g5R508U5ykm1WoKe5Q366" }
      locale: { eq: $locale }
    ) {
      ...mainTopNavAbTestFragment
    }
  }
`;

const getLinkUrl = ({ urlSlug, orderDate }: { urlSlug: string; orderDate: string }) => {
  const year = orderDate.split('-')[0];
  return `/press-releases/${year}/${urlSlug}/`;
};

const buildNavLinks = (years: number[], selectedYear: number) =>
  years.map((year, index) => {
    const currentYear = moment(new Date()).year();
    const linkYear = currentYear === year ? '' : `${year}/`;
    const linkClass = selectedYear === year ? 'docmenu__item--active' : '';
    return (
      <li key={`yearLink${index}`} className="docmenu__document">
        <LocaleLink className={linkClass} to={`/press-releases/` + linkYear}>
          {year}
        </LocaleLink>
      </li>
    );
  });

const PressReleasesTemplate: FC<PressReleaseTemplateProps> = ({ data, pageContext }) => {
  const translate = useTranslations();
  const pressItems = data.allPressRelease.nodes.map((p, index) => {
    return (
      <li key={`yearLink${index}`}>
        <div className="marketing-block__press-piece">
          <LocaleLink to={getLinkUrl(p)}>{p.title}</LocaleLink>
          <div>
            <strong>{moment(p.orderDate).format('MMMM DD, YYYY')}</strong>
          </div>
        </div>
      </li>
    );
  });

  return (
    <Layout
      redwoodNavData={data.redwoodNavData}
      redwoodRightNavData={data.redwoodRightNavData}
      utilityNavData={data.utilityNavData}
      pageContext={pageContext}
      topNavData={data.navData}
      topNavAbTestData={data.navData}
      footerData={data.footerData}
    >
      <Helmet>
        <title>Cloudflare | Press Releases</title>
        <meta name="title" content={`Press Releases ${pageContext.selectedYear}`} />
        <meta
          name="description"
          content={`We list global press coverage about Cloudflare on this page.`}
        />
      </Helmet>
      <section className="tile tile--borderless">
        <div className="tile__content tile__content--fixed">
          <div className="grid">
            <div className="grid__container">
              <div className="grid__item grid__item--two-thirds">
                <h1 className="marketing-h1 text-left--desktop">
                  {translate('Press Releases')} {pageContext.selectedYear}
                </h1>
                <div>
                  <div className="marketing-block__press">
                    <ul>{pressItems}</ul>
                  </div>
                </div>
              </div>
              <div className="grid__item grid__item--third">
                <PressReleaseContactInfo variant="press-page" />
                <div className="docmenu">
                  <div className="docmenu__heading js-docmenu-toggle">
                    {translate('Archive')}
                    <span className="icon icon-down-arrow"></span>
                  </div>
                  <div className="docmenu__separator"></div>
                  <ul className="docmenu__group">
                    {buildNavLinks(pageContext.years, pageContext.selectedYear)}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PressReleasesTemplate;
